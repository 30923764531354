import {SKIN_TONE_SURROGATES} from '~/Constants';
import emojiData from '~/assets/emojis.json';
import {i18n} from '~/i18n';
import {ActivityIcon} from '~/icons/ActivityIcon';
import {FlagIcon} from '~/icons/FlagIcon';
import {FoodIcon} from '~/icons/FoodIcon';
import {LoveIcon} from '~/icons/LoveIcon';
import {MagnetIcon} from '~/icons/MagnetIcon';
import {NatureIcon} from '~/icons/NatureIcon';
import {SmileyIcon} from '~/icons/SmileyIcon';
import {TravelIcon} from '~/icons/TravelIcon';
import EmojiStore from '~/stores/EmojiStore';
import * as EmojiUtils from '~/utils/EmojiUtils';

type EmojiDataRaw = Readonly<{
	names: ReadonlyArray<string>;
	surrogates: string;
	skins?: ReadonlyArray<EmojiSkinRaw>;
}>;

type EmojiSkinRaw = Readonly<{
	names: ReadonlyArray<string>;
	surrogates: string;
}>;

type EmojiSkinInfo = Readonly<{
	url: string;
	name: string;
	surrogatePair: string;
}>;

type EmojiCategory = keyof typeof emojiData;

const EMOJI_CATEGORIES = Object.freeze([
	'people',
	'nature',
	'food',
	'activity',
	'travel',
	'objects',
	'symbols',
	'flags',
] as const);

export const EMOJI_NAME_RE = /^:([^\s:]+?(?:::skin-tone-\d)?):/;
export const EMOJI_NAME_AND_SKIN_TONE_RE = /:([\w+-]+)(?:::skin-tone-\d)?:/g;

const CATEGORY_ICONS = Object.freeze({
	people: SmileyIcon,
	nature: NatureIcon,
	food: FoodIcon,
	activity: ActivityIcon,
	travel: TravelIcon,
	objects: MagnetIcon,
	symbols: LoveIcon,
	flags: FlagIcon,
} as const);

export class UnicodeEmoji {
	private readonly _data: Readonly<{
		uniqueName: string;
		names: ReadonlyArray<string>;
		allNamesString: string;
		skins: ReadonlyArray<EmojiSkinRaw>;
		surrogates: string;
		index: number;
		hasSkins: boolean;
		defaultURL: string;
		skinsByName: ReadonlyMap<string, EmojiSkinInfo>;
		urlForSkinTone: ReadonlyMap<string, string>;
	}>;

	constructor(data: EmojiDataRaw, index: number) {
		const skinsByName = new Map<string, EmojiSkinInfo>();
		const urlForSkinTone = new Map<string, string>();
		const skins = data.skins ?? [];
		const hasSkins = skins.length > 0;

		if (hasSkins) {
			skins.forEach((skin, i) => {
				const url = EmojiUtils.getURL(skin.surrogates);
				urlForSkinTone.set(skin.surrogates, url);
				const nameWithSkin = `${data.names[0]}::skin-tone-${i + 1}`;
				skinsByName.set(
					nameWithSkin,
					Object.freeze({
						name: nameWithSkin,
						surrogatePair: skin.surrogates,
						url,
					}),
				);
			});
		}

		this._data = Object.freeze({
			uniqueName: data.names[0],
			names: Object.freeze([...data.names]),
			allNamesString: data.names.length > 1 ? `:${data.names.join(': :')}:` : `:${data.names[0]}:`,
			skins: Object.freeze([...skins]),
			surrogates: data.surrogates,
			index,
			hasSkins,
			defaultURL: EmojiUtils.getURL(data.surrogates),
			skinsByName: Object.freeze(new Map(skinsByName)),
			urlForSkinTone: Object.freeze(new Map(urlForSkinTone)),
		});
	}

	get uniqueName(): string {
		return this._data.uniqueName;
	}
	get names(): ReadonlyArray<string> {
		return this._data.names;
	}
	get allNamesString(): string {
		return this._data.allNamesString;
	}
	get hasSkins(): boolean {
		return this._data.hasSkins;
	}
	get index(): number {
		return this._data.index;
	}
	get defaultURL(): string {
		return this._data.defaultURL;
	}

	get url(): string {
		const skinTone = EmojiStore.getSkinTone();
		if (!this.hasSkins || !skinTone) return this.defaultURL;
		return this._data.urlForSkinTone.get(this.surrogatePair) ?? this.defaultURL;
	}

	get name(): string {
		const skinTone = EmojiStore.getSkinTone();
		if (!this.hasSkins || !skinTone) return this.uniqueName;
		const skinName = surrogateToName.get(skinTone);
		return skinName ? `${this.uniqueName}::${skinName}` : this.uniqueName;
	}

	get surrogatePair(): string {
		const skinInfo = this._data.skinsByName.get(this.name);
		return skinInfo?.surrogatePair ?? this._data.surrogates;
	}

	get baseSurrogate(): string {
		return this._data.surrogates;
	}

	forEachSkin(callback: (skin: Readonly<EmojiSkinInfo>) => void): void {
		this._data.skinsByName.forEach(callback);
	}
}

function initializeEmojiData() {
	const emojisByCategory = new Map<EmojiCategory, ReadonlyArray<UnicodeEmoji>>();
	const surrogateToName = new Map<string, string>();
	const emojis: Array<UnicodeEmoji> = [];

	let emojiIndex = 0;

	for (const category of EMOJI_CATEGORIES) {
		const categoryEmojis: Array<UnicodeEmoji> = [];
		const categoryData = (emojiData as Record<EmojiCategory, ReadonlyArray<EmojiDataRaw>>)[category];

		for (const emojiDatum of categoryData) {
			const emoji = new UnicodeEmoji(emojiDatum, emojiIndex++);
			surrogateToName.set(emoji.baseSurrogate, emoji.uniqueName);
			emoji.forEachSkin((skin) => {
				surrogateToName.set(skin.surrogatePair, skin.name);
			});
			emojis.push(emoji);
			categoryEmojis.push(emoji);
		}

		emojisByCategory.set(category, Object.freeze(categoryEmojis));
	}

	for (const [index, surrogate] of SKIN_TONE_SURROGATES.entries()) {
		const skinTone = `skin-tone-${index + 1}`;
		surrogateToName.set(surrogate, skinTone);
	}

	return {
		emojisByCategory: Object.freeze(new Map(emojisByCategory)),
		surrogateToName: Object.freeze(new Map(surrogateToName)),
		emojis: Object.freeze([...emojis]),
	};
}

const {emojisByCategory, surrogateToName, emojis} = initializeEmojiData();

export const convertSurrogateToName = (surrogate: string, includeColons = true, defaultName = ''): string => {
	const name = surrogateToName.get(surrogate) ?? defaultName;
	return includeColons ? `:${name}:` : name;
};

export const getCategoryIcon = (category: EmojiCategory) => {
	return CATEGORY_ICONS[category] ?? CATEGORY_ICONS.people;
};

export const getCategoryLabel = (category: EmojiCategory): string => {
	switch (category) {
		case 'people':
			return i18n.Messages.EMOJI_CATEGORY_PEOPLE;
		case 'nature':
			return i18n.Messages.EMOJI_CATEGORY_NATURE;
		case 'food':
			return i18n.Messages.EMOJI_CATEGORY_FOOD;
		case 'activity':
			return i18n.Messages.EMOJI_CATEGORY_ACTIVITY;
		case 'travel':
			return i18n.Messages.EMOJI_CATEGORY_TRAVEL;
		case 'objects':
			return i18n.Messages.EMOJI_CATEGORY_OBJECTS;
		case 'symbols':
			return i18n.Messages.EMOJI_CATEGORY_SYMBOLS;
		case 'flags':
			return i18n.Messages.EMOJI_CATEGORY_FLAGS;
		default:
			return '';
	}
};

export const getCategoryForEmoji = (emoji: UnicodeEmoji): EmojiCategory | null => {
	return EMOJI_CATEGORIES.find((category) => emojisByCategory.get(category)?.includes(emoji)) ?? null;
};

export const getCategories = (): ReadonlyArray<EmojiCategory> => {
	return EMOJI_CATEGORIES;
};

export const forEachEmoji = (callback: (emoji: Readonly<UnicodeEmoji>) => void): void => {
	emojis.forEach(callback);
};
